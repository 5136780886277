import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import Styled from "styled-components";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import Typography from "@material-ui/core/Typography";
import { Add, Remove } from "@material-ui/icons";
import { DatePicker } from "@material-ui/pickers";
import { Select, MenuItem, InputLabel, FormControl } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { Wrapper } from "../../components/Control/Flexbox";
import { CenteredLogoImg } from "../../golbalStyles/global.styled";
import { images, textInputs } from "../../constants/constants";
import SelectPart from "./SelectPart";
import Modal from "../../components/Control/Modal";
import { CloseButton } from "../../GlobalUtils/ButtonsGlobal";
import useConfirm from "../../components/Control/useConfirm";
import { ButtonStyled, TableContainerStyled } from "../DailyProduction/styles";
import TableReadEditCell from "../../components/TableEditCell";
import { useQuery } from "../../components/Control/useQuery";
import { mdiFileExcel } from "@mdi/js";
import Icon from "@mdi/react";
import Tooltip from "@material-ui/core/Tooltip";
import TableToExcel from "@dayalk/table-to-excel";
import formatDate from "../../components/Control/formatDate";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { useAuth } from "../../context/GlobalContext";
import CustomSelect from "../../common/CustomSelect";

const Table = Styled.table`
	margin-bottom: 1rem;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
    }

    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
      font-weight:500;
    }

    .input {
        outline: none;
        width: 200px;
        border: none;
        height: 50px;
        text-align:center;
    }

`;

const ControlledInput = ({ onUpdate, disable, defaultvalue }) => {
  const [value, setState] = useState(defaultvalue);

  useEffect(() => {
    if (disable) {
      setState("");
    }
  }, [disable]);

  const handleChange = (e) => {
    setState(e.target.value);
    onUpdate(e.target.value);
  };

  return (
    <input
      className="input"
      value={value}
      disabled={disable}
      onChange={handleChange}
    />
  );
};

const dropdownStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
  },
  select: {
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
  underline: {
    borderBottom: "0px solid red !important",
    "&:hover": {
      borderBottom: "0px solid rgba(0,0,0,0)",
    },
  },
});

const ScrapAddEdit = () => {
  const classes = dropdownStyles();
  const { id } = useParams();
  const { state } = useAuth();
  const [isSelectPartModal, selectSelectPartModal] = useState(false);
  const [render, setRender] = useState(false);
  const [partDetails, setPartDetails] = useState([]);
  const indexSelectPart = useRef(0);
  const history = useHistory();
  const query = useQuery();
  const [departmnetData, setDepartmentData] = useState([]);
  const [autoCompleteValue, setAutoCompleteValue] = useState({});
  const [failures, setFailures] = useState(new Array(25).fill().map((_) => []));
  const [scrapNoteData, setScrapNoteData] = useState({
    dailyProduction_date: new Date(),
    processes: new Array(25).fill().map((_) => ({})),
  });
  const [filter, setFilter] = useState("");

  const [scrapLocations, setScrapLocations] = useState([]);
  const [partData, setPartData] = useState([]);

  const isView = query.get("isView") === "true" ? true : false;

  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?"
  );
  const [DialogSave, confirmSave] = useConfirm(
    "SAVE",
    "Are you sure you want to save?"
  );
  const [DialogUpdate, confirmUpdate] = useConfirm(
    "SAVE",
    "Are you sure you want to Submit?"
  );

  useEffect(() => {
    axios
      .get(`/api/getPartDetails`)
      .then((res) => {
        setPartDetails(res.data?.results);
      })
      .catch((err) => console.log(err));
    axios
      .get(`/api/getPartDetailsAutoComplete`)
      .then((res) => {
        setPartData(res.data?.results);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    let isMounted = true;
    axios
      .get(`/api/getAllScrapLocations`)
      .then((res) => {
        if (isMounted) {
          setScrapLocations(res.data?.result); // Update state only if component is mounted
        }
      })
      .catch((err) => console.log(err));
      return () => {
        isMounted = false; // Set flag to false when component unmounts
      };
  }, []);
  
  useEffect(() => {
    axios
      .get(`/api/getAllDepartments`)
      .then((res) => {
        setDepartmentData(res.data?.result);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleChangeInput = (e, index) => {
    const name = e.target.name;
    const value = e.target.value;
    if (index === 0 || index) {
      const values = { ...scrapNoteData };
      values.processes[index][name] = value;
      setScrapNoteData(values);
    }
  };

  const handleFailures = async (partId, index) => {
    axios
      .get(`/api/getFailuresByPart/${partId}`)
      .then((res) => {
        if (res.data) {
          failures[index] = res.data;
          setRender((prev) => !prev);
        }
      })
      .catch((err) => {
        console.log(err);
        setRender((prev) => !prev);
      });
  };

  useEffect(() => {
    if (!id) return;
    //if (partDetails.length) {
      axios
        .get(`/api/getScrapNoteById/${id}?filter=${filter}`)
        .then((res) => {
          res.data.processes.map((item, index) => {
            if (item.part_id) {
              handleFailures(item.part_id._id, index);
            }
          });
          setScrapNoteData(res.data);
        })
        .catch((err) => console.log(err));
    //}
  }, [filter]);

  const selectFailure = (e, index) => {
    const value = e.target.value;
    scrapNoteData.processes[index].reason = value;
  };

  const handlePartDetails = (partId, value, index) => {
    scrapNoteData.processes[indexSelectPart.current].part_id = partId;
    scrapNoteData.processes[indexSelectPart.current].part_number = value;
    const getPartNo = partDetails.filter((part) => part._id === partId);

    scrapNoteData.processes[indexSelectPart.current].part_name =
      getPartNo[0].part_name;
    handleFailures(getPartNo[0]._id, indexSelectPart.current);
  };

  const resetPartDetails = () => {
    scrapNoteData.processes[indexSelectPart.current].part_id = null;
    scrapNoteData.processes[indexSelectPart.current].part_number = null;
    scrapNoteData.processes[indexSelectPart.current].part_name = null;
    failures[indexSelectPart.current] = [];
    setRender((prev) => !prev);
  };

  const handleSubmitAndSave = async (bool) => {
    if (id) {
      let ans = await confirmUpdate();
      if (ans) {
        scrapNoteData.isSubmitted = bool;
        scrapNoteData.report_prepared_by = state.user?.name;
        axios
          .put(`/api/updateScrapNoteById/${id}`, scrapNoteData)
          .then((res) => {
            if (res.data.status === "success") {
              alert("updated successfully");
            }
          })
          .catch((err) => console.log(err));
      }
    } else {
      let ans = await confirmSave();
      if (ans) {
        scrapNoteData.isSubmitted = bool;
        scrapNoteData.is_admin_request = "pending";
        scrapNoteData.report_prepared_by = state.user?.name;

        axios
          .post(`/api/createScrapNote`, scrapNoteData)
          .then((res) => {
            if (res.data.status === "success") {
              alert("created successfully");
              history.push("/scrapNoteList");
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };

  const handleScroll = (scrolltype) => {
    if (scrolltype === "right") {
      document.getElementById("scroll").scrollLeft += 80;
    } else {
      document.getElementById("scroll").scrollLeft -= 80;
    }
  };

  return (
    <section>
      <div
        style={{
          paddingBottom: "4rem",
        }}
      >
        <ButtonStyled
          left
          onClick={(e) => {
            handleScroll("left");
          }}
        >
          <div>
            <ArrowBackIosIcon style={{ color: "#fff" }} />
          </div>
        </ButtonStyled>
        <ButtonStyled
          right
          onClick={(e) => {
            handleScroll("right");
          }}
        >
          <div>
            <ArrowForwardIosIcon style={{ color: "#fff" }} />
          </div>
        </ButtonStyled>

        <div className="divflex">
          <Typography
            style={{
              fontSize: "24px",
              fontFamily: "Nunito",
            }}
          >
            SCRAP NOTE REPORT
          </Typography>
          {!isView ? (
            <div style={{ display: "flex" }}>
              <Wrapper
                style={{
                  gap: "1rem",
                }}
              >
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{
                    backgroundColor: "#003566",
                    marginRight: "0.5rem",
                  }}
                  startIcon={<SaveIcon />}
                  onClick={() => {
                    handleSubmitAndSave(false);
                  }}
                >
                  Save & Continue
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{
                    backgroundColor: "#064a29",
                    marginRight: "0.5rem",
                  }}
                  startIcon={<CloudUploadIcon />}
                  onClick={() => {
                    handleSubmitAndSave(true);
                  }}
                >
                  Submit
                </Button>
                <CloseButton
                  onClick={async () => {
                    let ans = await confirmClose();
                    if (ans) {
                      history.goBack();
                    }
                  }}
                />
              </Wrapper>
            </div>
          ) : (
            <Tooltip arrow title="Export as spreadsheet">
              <div>
                <Button
                  style={{
                    backgroundColor: "#161a1d",
                    borderColor: "#161a1d",
                    color: "white",
                    width: "fit-content",
                    borderRadius: "0",
                    paddingTop: "9px",
                  }}
                  onClick={() => {
                    TableToExcel.convert(
                      document.getElementById("machinetable"),
                      {
                        name: "Scrape Note Report.xlsx",
                        sheet: {
                          name: "Scrape Note Report",
                        },
                      }
                    );
                  }}
                >
                  <Icon path={mdiFileExcel} size={1.2} />
                </Button>
              </div>
            </Tooltip>
          )}
        </div>
        <TableContainerStyled id="scroll">
          <Table
            cellSpacing="0"
            style={{ marginTop: "0.6rem", backgroundColor: "white" }}
            data-default-wrap="true"
            id="machinetable"
            data-cols-width="15, 15, 15, 20, 15, 18, 15, 15, 15, 15, 15, 15"
          >
            <thead>
              <tr style={{ height: "70px" }}>
                <th
                  colSpan="11"
                  align="left"
                  style={{
                    border: "1px solid black",
                    fontSize: "1.6rem",
                    textAlign: "center",
                    position: "relative",
                  }}
                >
                  <CenteredLogoImg>
                    <img
                      src={images.arcLogo}
                      alt={textInputs.companyName}
                      height="46"
                      width="120"
                    />
                    <span>{textInputs.companyName}</span>
                  </CenteredLogoImg>
                </th>
                <th
                  colSpan={2}
                  style={{
                    border: "1px solid black",
                  }}
                >
                  DOC. NO:- ARC/F/QAD/101
                </th>
              </tr>

              <tr>
                <th
                  colSpan="11"
                  align="center"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    backgroundColor: "#1d1d1d",
                    color: "white",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  SCRAP NOTE REPORT
                </th>
                <th colSpan={2} style={{ border: "1px solid black" }}>
                  REV NO./DATE :- 01/29.09.2022
                </th>
              </tr>
              <tr>
                <th colSpan={3} style={{ border: "1px solid black" }}>
                  <Wrapper style={{ gap: "5px" }}>
                    DATE:
                    {isView ? (
                      formatDate(scrapNoteData.scrapnote_date)
                    ) : (
                      <DatePicker
                        name="date_performance"
                        format="dd/MM/yyyy"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        style={{
                          backgroundColor: "#f8f9fa",
                        }}
                        value={scrapNoteData.scrapnote_date}
                        onChange={(date) => {
                          scrapNoteData.scrapnote_date = date;
                          setRender((prev) => !prev);
                        }}
                      />
                    )}
                  </Wrapper>
                </th>
                <th style={{ border: "1px solid black" }}>Location</th>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                  }}
                  colSpan={2}
                >
                  <TableReadEditCell
                    isView={isView}
                    type="text"
                    value={scrapNoteData.location}
                    name="location"
                    handleChange={(e) => {
                      scrapNoteData.location = e.target.value;
                      setRender((prev) => !prev);
                    }}
                  />
                  
                </td>
                
                <th style={{ border: "1px solid black" }}>Scrap Location</th>
                {/* <td
                  align="center"
                  style={{
                    border: "1px solid black",
                  }}
                  colSpan={2}
                >
                  
                  {isView ? (
                    <div>{scrapNoteData.scrap_location_name}</div>
                  ):(

                    <CustomSelect
                      value={scrapNoteData.scrap_location_name || ""}
                      InputLabel={id ? scrapNoteData.scrap_location_name || "Select Location" : "Select Location"}
                      name="scrap_location_name"
                      onChange={(e) => {
                        scrapNoteData.scrap_location_name = e.target.value;
                        setRender((prev) => !prev);
                      }}
                      placeholder={id ? scrapNoteData.scrap_location_name : ""}
                      
                    >
                      {scrapLocations.map((location) => (
                        <>
                       
                        <MenuItem key={'none'} value=''>
                          None
                        </MenuItem>
                        <MenuItem key={location._id} value={location.location_name}>
                          {location.location_name}
                        </MenuItem>
                        </>
                      ))}
                    </CustomSelect>
                  )}
                </td> */}
                <td
  align="center"
  style={{
    border: "1px solid black",
  }}
  colSpan={2}
>
  {isView ? (
    <div>{scrapNoteData.scrap_location_name || "None"}</div>
  ) : (
    <CustomSelect
      value={scrapNoteData.scrap_location_name || ""}
      InputLabel={id ? scrapNoteData.scrap_location_name || "Select Location" : "Select Location"}
      name="scrap_location_name"
      onChange={(e) => {
        scrapNoteData.scrap_location_name = e.target.value;
        setRender((prev) => !prev);
      }}
      placeholder={id ? scrapNoteData.scrap_location_name : ""}
    >
      {/* <MenuItem value="" disabled>
        Select Location
      </MenuItem> */}
      <MenuItem value="">
        None
      </MenuItem>
      {scrapLocations.map((location) => (
        <MenuItem key={location._id} value={location.location_name}>
          {location.location_name}
        </MenuItem>
      ))}
    </CustomSelect>
  )}
</td>
<FormControl
  variant="filled"
  className="select-field"
  style={{
    width: '200px',
    marginRight: "1.5rem",
    marginTop: "0.8rem",
    flex: "0.2",
    type: "flex",
    justifyItems: "right"
  }}
>
  <InputLabel>Filter by Part</InputLabel>
  <Select
    name="filter"
    onChange={(e) => setFilter(e.target.value)}
    value={filter}
  >
    <MenuItem value="">None</MenuItem>
    {Array.from(
      new Set(
        partData?.map((location) => location.part_name.trim().toUpperCase()) // Normalize by trimming and converting to uppercase
      )
    ).map((uniquePart) => (
      <MenuItem key={uniquePart} value={uniquePart}>
        {uniquePart}
      </MenuItem>
    ))}
  </Select>
</FormControl>



                {/* <FormControl
                              variant="filled"
                              className="select-field"
                              style={{
                                width:'200px',
                                marginRight: "1.5rem",
                                marginTop: "0.8rem",
                                flex: "0.2",
                                type: "flex",
                                justifyItems: "right"
                              }}
                            >
                              <InputLabel>Filter by Part</InputLabel>
                              <Select
                                name="filter"
                                onChange={(e) => setFilter(e.target.value)}
                                value={filter}
                              >
                                <MenuItem value="">None</MenuItem>
                                {partData?.map((location) => (
                                  <MenuItem key={location._id} value={location.part_name}>
                                    {location.part_name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl> */}
                <th colSpan={7} style={{ border: "1px solid black" }}></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {[
                  "SNO.",
                  "PART NO.",
                  "PART NAME",
                  "SUPPLIER & INHOUSE DEPT NAME",
                  "QTY",
                  "DESCRIPTION OF NON CONFORMITY",
                  "REASON OF REJECTION( PRODUCTION STATEMENT)",
                  "DEBIT NOTE NO.",
                  "RATE / PCS",
                  "AMOUNT",
                  "TOTAL WEIGHT",
                  "REMARKS",
                ].map((heading, index) => (
                  <th
                    key={index}
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  >
                    {heading}
                  </th>
                ))}
              </tr>

              {scrapNoteData.processes.map((item, index) => (
                <tr key={item._id || index}>
                  <th
                    style={{
                      width: "100px",
                      border: "1px solid black",
                      textAlign: "center",
                      padding: "0 0.4rem",
                      backgroundColor: "#f8f9fa",
                    }}
                  >
                    {index + 1}
                  </th>
                  <th
                    style={{
                      width: "100px",
                      border: "1px solid black",
                      textAlign: "center",
                      padding: "0 0.4rem",
                      backgroundColor: "#f8f9fa",
                    }}
                  >
                    {isView ? (
                      item?.part_number || item?.part_id?.part_number
                    ) : (
                      <Wrapper
                        justify="space-between"
                        style={{ width: "200px", backgroundColor: "#f8f9fa" }}
                      >
                        <p
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          {item?.part_id?.part_number ||
                            item?.part_number ||
                            "Pick Part No."}
                        </p>
                        {item?.part_number || item?.part_id?.part_number ? (
                          <Wrapper
                            className="pir_add_modal"
                            style={{ backgroundColor: "#003566" }}
                            justify="center"
                            onClick={() => {
                              item.part_number = null;
                              item.part_id = null;
                              item.part_name = null;
                              failures[index] = [];
                              setRender((prev) => !prev);
                            }}
                          >
                            <Remove />
                          </Wrapper>
                        ) : (
                          <Wrapper
                            className="pir_add_modal"
                            justify="center"
                            style={{ backgroundColor: "#495057" }}
                            onClick={() => {
                              indexSelectPart.current = index;
                              selectSelectPartModal(true);
                            }}
                          >
                            <Add />
                          </Wrapper>
                        )}
                      </Wrapper>
                    )}
                  </th>
                  <td
                    style={{
                      minWidth: "180px",
                      border: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    <div style={{ maxHeight: "50px", overflow: "auto" }}>
                      {item?.part_name || item?.part_id?.part_name}
                    </div>
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                    }}
                  >
                    <TableReadEditCell
                      {...{ index }}
                      isView={isView}
                      type="text"
                      value={item.supplier_dept_name}
                      name="supplier_dept_name"
                      handleChange={(e, index) => handleChangeInput(e, index)}
                    />
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                    }}
                  >
                    <TableReadEditCell
                      {...{ index }}
                      isView={isView}
                      type="number"
                      name="qty"
                      value={item.qty}
                      handleChange={(e, index) => handleChangeInput(e, index)}
                    />
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                    }}
                  >
                    <TableReadEditCell
                      {...{ index }}
                      isView={isView}
                      type="text"
                      value={item.desc_non_conformity}
                      name="desc_non_conformity"
                      handleChange={(e, index) => handleChangeInput(e, index)}
                    />
                  </td>
                  <td>
                    {isView ? (
                      item.reason
                    ) : (
                      <FormControl
                        style={{
                          width: "250px",
                          border: "none",
                        }}
                        fullWidth={true}
                        variant="filled"
                        classes={{
                          root: classes.root,
                        }}
                      >
                        <InputLabel>Select Reason</InputLabel>
                        <Select
                          name="reason"
                          defaultValue={item.reason || ""}
                          classes={{
                            // underline: classes.underline,
                            root: classes.root,
                            select: classes.select,
                          }}
                          style={{ fontSize: "0.8rem" }}
                          disableUnderline
                          onChange={(e) => selectFailure(e, index)}
                        >
                          {failures[index].map((data, PIndex) => (
                            <MenuItem
                              className="option"
                              style={{ fontSize: "0.8rem" }}
                              key={PIndex}
                              value={`${data.potential_mode} , ${data.potential_cause[0]}`}
                            >
                              {data.potential_mode} , {data.potential_cause[0]}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </td>
                  {/* <td>
                    {isView ? (
                      item?.department_id?.department_name
                    ) : (
                      <Autocomplete
                        options={departmnetData || []}
                        getOptionLabel={(option) => option.department_name}
                        value={item.department_id}
                        style={{
                          paddingLeft: "0.5rem",
                          width: "100%",
                          marginRight: "6rem",
                        }}
                        onChange={(e, value) => {
                          item.department_id = value;
                          setRender((prev) => !prev);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                            }} 
                          />
                        )}
                      />
                    )}
                  </td> */}
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                    }}
                  >
                    <TableReadEditCell
                      {...{ index }}
                      isView={isView}
                      type="number"
                      name="debit_note_num"
                      value={item.debit_note_num}
                      handleChange={(e, index) => handleChangeInput(e, index)}
                    />
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                    }}
                  >
                    <TableReadEditCell
                      {...{ index }}
                      isView={isView}
                      type="text"
                      name="rate_pcs"
                      value={item.rate_pcs}
                      handleChange={(e, index) => handleChangeInput(e, index)}
                    />
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                    }}
                  >
                    <TableReadEditCell
                      {...{ index }}
                      isView={isView}
                      type="number"
                      name="amount"
                      value={item.amount}
                      handleChange={(e, index) => handleChangeInput(e, index)}
                    />
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                    }}
                  >
                    <TableReadEditCell
                      {...{ index }}
                      isView={isView}
                      type="number"
                      name="total_weight"
                      value={item.total_weight}
                      handleChange={(e, index) => handleChangeInput(e, index)}
                    />
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                    }}
                  >
                    <TableReadEditCell
                      {...{ index }}
                      isView={isView}
                      type="text"
                      name="remarks"
                      value={item.remarks}
                      handleChange={(e, index) => handleChangeInput(e, index)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr style={{ height: "60px" }} data-height="35">
                <td
                  align="left"
                  colSpan={3}
                  style={{ fontWeight: 500, border: "1px solid black" }}
                >
                  Prepared By:
                </td>
                <td
                  align="left"
                  colSpan={3}
                  style={{
                    border: "1px solid black",
                    textTransform: "capitalize",
                  }}
                >
                  {scrapNoteData?.report_prepared_by}
                </td>

                <td
                  align="left"
                  colSpan={3}
                  style={{ fontWeight: 500, border: "1px solid black" }}
                >
                  Approved By:
                </td>
                <td
                  align="left"
                  style={{
                    border: "1px solid black",
                    textTransform: "capitalize",
                  }}
                  colSpan={3}
                >
                  {scrapNoteData?.is_admin_request === "accepted" &&
                    scrapNoteData?.report_approved_by}
                </td>
              </tr>
            </tfoot>
          </Table>
        </TableContainerStyled>
      </div>
      <Modal
        openPopup={isSelectPartModal}
        title="Select PART"
        closeModal={(e) => selectSelectPartModal(false)}
      >
        <SelectPart
          {...{ handlePartDetails, resetPartDetails }}
          processes={scrapNoteData.processes[indexSelectPart.current]}
        />
      </Modal>
      <DialogSave isSubmitContent={true} />
      <DialogClose isSubmitContent={false} />
      <DialogUpdate isSubmitContent={false} />
    </section>
  );
};

export default ScrapAddEdit;
