import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { Search } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
import useDebounce from "../../components/Control/useDebounce";
import Input from "../../components/Control/Input";
import { Wrapper } from "../../components/Control/Flexbox";
import formatDate from "../../components/Control/formatDate";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import CheckIcon from "@material-ui/icons/Check";
const useStyles = makeStyles({
  table: {
    width: "100%",
    marginTop: "1.5rem",
  },
});

const ForMTable = ({
  departmentId,
  processes,
  reset4m,
  handle4m,
  documentType,
}) => {
  const classes = useStyles();
  const [filter, setFilter] = useState("desc_4m");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState();
  const [perPage, setPerPage] = useState();

  const fetchData = async () => {
    if (departmentId) {
      if (documentType === "machine") {
        const res = await axios
          .get(
            `/api/get/machineByDepartment?departmentId=${departmentId}&page=${page}&sortBy=${filter}&search=${search}`,
          )
          .catch((err) => {
            console.log(err);
          });
        setData(res.data?.result);
        setPerPage(res.data?.perPage);
        setTotalPages(res.data?.pages);
      }
      if (documentType === "operator") {
        axios
          .get(
            `/api/employeeForDailyProduction?departmentId=${departmentId}&desg=${'OPERATOR'}&page=${page}&sortBy=${filter}&search=${search}&perPage=10`,
          )
          .then((res) => {
            setPerPage(res.data?.perPage);
            setData(res.data?.results);
            setTotalPages(res.data?.pages);
          })
          .catch((err) => console.log(err));
      }
    }
  };

  useDebounce(fetchData, 300, [
    page,
    search,
    filter,
    documentType,
    departmentId,
  ]);
  return (
    <main>
      <Wrapper>
        <div style={{ flex: "0.8" }}>
          <Input
            placeholder="Search"
            variant="filled"
            style={{
              width: "95%",
              marginLeft: "1.2rem",
            }}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <FormControl
          variant="filled"
          className="select-field"
          style={{ marginRight: "1.5rem", marginTop: "0.1rem", flex: "0.2" }}
        >
          <InputLabel>Filter</InputLabel>
          <Select
            name="part_type"
            onChange={(e) => setFilter(e.target.value)}
            defaultValue={filter}
          >
            <MenuItem value="desc_4m">A - Z</MenuItem>
            <MenuItem value="createdAt">Most Recent First</MenuItem>
          </Select>
        </FormControl>
      </Wrapper>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow style={{ backgroundColor: "white", padding: "6px" }}>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              #
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              {documentType === "machine" && "Machine"}{" "}
              {documentType === "operator" && "Operator"}
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Created At
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        {data && (
          <TableBody>
            {data.map((item, idx) => (
              <React.Fragment key={item._id}>
                <TableRow className={classes.rootRow}>
                  <TableCell align="center">
                    {perPage * (page - 1) + idx + 1}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {documentType === "machine" && item.machine_no}
                    {documentType === "operator" && item.employee_name}
                  </TableCell>
                  <TableCell align="center">
                    {formatDate(item.createdAt)}
                  </TableCell>
                  <TableCell align="center">
                    {documentType === "machine" &&
                      (item.machine_no === processes.machine_no ? (
                        <>
                          {" "}
                          <Tooltip title="Status" arrow>
                            <IconButton size="small">
                              <CheckIcon
                                style={{ fontSize: "1.4rem", color: "#003566" }}
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Remove" arrow>
                            <IconButton
                              size="small"
                              style={{ marginLeft: "0.4rem" }}
                              onClick={() => {
                                reset4m("machine");
                              }}
                            >
                              <RemoveIcon
                                style={{ fontSize: "1.4rem", color: "#c80202" }}
                              />
                            </IconButton>
                          </Tooltip>
                        </>
                      ) : (
                        <Tooltip title="Add" arrow>
                          <IconButton
                            size="small"
                            onClick={() => {
                              handle4m(item.machine_no, "machine");
                            }}
                          >
                            <AddIcon
                              style={{ fontSize: "1.4rem", color: "#003566" }}
                            />
                          </IconButton>
                        </Tooltip>
                      ))}
                    {documentType === "operator" &&
                      (item.employee_name === processes.operator_name ? (
                        <>
                          {" "}
                          <Tooltip title="Status" arrow>
                            <IconButton size="small">
                              <CheckIcon
                                style={{ fontSize: "1.4rem", color: "#003566" }}
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Remove" arrow>
                            <IconButton
                              size="small"
                              style={{ marginLeft: "0.4rem" }}
                              onClick={() => {
                                reset4m("operator");
                              }}
                            >
                              <RemoveIcon
                                style={{ fontSize: "1.4rem", color: "#c80202" }}
                              />
                            </IconButton>
                          </Tooltip>
                        </>
                      ) : (
                        <Tooltip title="Add" arrow>
                          <IconButton
                            size="small"
                            onClick={() => {
                              handle4m(item.employee_name, "operator");
                            }}
                          >
                            <AddIcon
                              style={{ fontSize: "1.4rem", color: "#003566" }}
                            />
                          </IconButton>
                        </Tooltip>
                      ))}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        )}
      </Table>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1rem",
          marginBottom: "4rem",
        }}
      >
        <Pagination
          count={totalPages}
          variant="outlined"
          shape="rounded"
          onChange={(e, number) => setPage(number)}
        />
      </div>
    </main>
  );
};

export default ForMTable;
