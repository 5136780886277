import React from "react";
import Header from "../components/Header";
// import Footer from "../components/Footer";
import ControlPlan from "../components/ControlPlan";
import AdminModules from "../Pages/AdminModules";
import PageNotFound from "../components/PageNotFound";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import PCDIndex from "../Pages/PCD";
import Admin from "../components/Admin";
import Login from "../components/Login";
import TablePir from "../Pages/PIR/ViewPirExcel/TablePir";
import CreatePir from "../Pages/PIR/CreatePir";
import SupervisorPirList from "../Pages/PIR/SupervisorPirList/SupervisorPirList";
import AdminEditPir from "../Pages/PIR/AdminEditPir";
import AdminPirList from "../Pages/PIR/AdminPirList/AdminPirList";
import Supervisor from "../components/Supervisor";
import ViewAdminPartTable from "../Pages/ViewAdminPartTable";
import RevisedTable from "../Pages/RevisedTable/RevisedTable";
import Test from "../components/Test";
import SupervisorEditPir from "../Pages/PIR/SupervisorEditPir";
import Account from "../components/Account";
import PfdDiagram from "../components/PfdDiagram";
import { useAuth } from "../context/GlobalContext";
import Users from "../components/Users/Users";
import DashBoard from "../components/DashBoard";
import SupervisorPartTable from "../Pages/SupervisorPartTable";
import PartWiseAnalysis from "../components/PartWiseAnalysis";
import FailureModeWiseRejection from "../components/FailureModeWiseRejection.jsx";
import FmeaTable from "../Pages/FmeaReports/FmeaTable";
import ViewSetupPiece from "../Pages/PIR/ViewSetupPiece";
import PdirMonthlyTable from "../Pages/PDIR/PdirMonthlyTable/PdirMonthlyTable";
import CreatePdir from "../Pages/PDIR/CreatePdir";
import UpdatePdir from "../Pages/PDIR/EditPdir";
import ViewPdirExcel from "../Pages/PDIR/ViewPdirTable";
import SupervisorView4MTable from "../Pages/4M/SupervisorView4MTable";
import AdminView4MTable from "../Pages/4M/AdminView4MTable";
import ViewSetupForm from "../Pages/4M/ViewSetupForm";
import ViewRequestForm from "../Pages/4M/ViewRequestForm";
import GeneratePPAP from "../components/GeneratePPAP";
import SelectPPAP from "../components/GeneratePPAP/SelectPPAP";
import EditPPAP from "../components/GeneratePPAP/EditPPAP";
import ViewPrintout from "../components/GeneratePPAP/ViewPrintout/ViewPrintout";
import FmeaAllList from "../Pages/FmeaReports/FmeaAllList";
import ViewFmeaTable from "../Pages/FmeaReports/ViewFmeaTable";
import MSATable from "../Pages/MSA/MSATable";
import CreateMSA from "../Pages/MSA/CreateMSA";
import EditMSA from "../Pages/MSA/EditMSA";
import ViewIncomingTable from "../Pages/IncomingReport/Incomingtable";
import CreateInspection from "../Pages/IncomingReport/CreateIncoming";
import ViewIncoming from "../Pages/IncomingReport/ViewIncoming";
import UpdateIncoming from "../Pages/IncomingReport/UpdateIncoming";
import PartList from "../Pages/AddEditDuplicatePart/PartList";
import QuickView from "../Pages/AddEditDuplicatePart/quickView/QuickView";
import CreatePfd from "../Pages/AddEditDuplicatePart/PFD/CreatePfd";
import CreatePfmea from "../Pages/AddEditDuplicatePart/PFMEA/CreatePfmea";
import CreateControlPlan from "../Pages/AddEditDuplicatePart/ControlPLan/CreateControlPlan";
import PfmeaIndex from "../Pages/Diagram/FMEA/PfmeaIndex";
import customerDrawingTable from "../Pages/CustomerDrawing/CustomerDrawing";
import ViewCustomerDrawing from "../Pages/CustomerDrawing/ViewCustomerDrawing";
import ViewDailyProduction from "../Pages/DailyProduction/DailyProduction";
import DialyProductionTable from "../Pages/DailyProduction/DailyProductionTable";
import ViewDailyProductionTable from "../Pages/DailyProduction/ViewDailyProductionTable";
import createProductionPlanning from "../Pages/ProductionPlanning/ProductionPlanning";
import createProductionPlanningTable from "../Pages/ProductionPlanning/ProductionPlanningTable";
import viewProductionPlanningTable from "../Pages/ProductionPlanning/ViewProductionPlanning";
import MaterialTcTable from "../Pages/MaterialTc/MaterialTcTable";
import ViewCMaterialTc from "../Pages/MaterialTc/ViewMaterialTC";
import ProcessProduct from "../Pages/ProcessProductValidation/ProcessProductValidation";
import ViewProcessProduct from "../Pages/ProcessProductValidation/ViewProcessProductValidation";
import DocumentDataControl from "../Pages/DocumentDataControl/DocumentDataControl";
import ViewDocumentDataControl from "../Pages/DocumentDataControl/ViewDocumentDataControl";
import MiscDocumentsDrawings from "../Pages/MiscDocumentsDrawings/MiscDocumentsDrawings";
import ViewMiscDocumentsDrawings from "../Pages/MiscDocumentsDrawings/ViewMiscDocumentsDrawings";
import Notebook from "../Pages/Notebooks";
import Notes from "../Pages/Notebooks/Notes";

import MachineList from "../Pages/Machine/MachineList";
import MaintenanceBreakdown from "../Pages/MaintenanceBreakdown";
import Maintenance from "../Pages/MaintenanceBreakdown/Maintenance/Maintenance";
import CreateEditPreventiveMaintenance from "../Pages/MaintenanceBreakdown/Maintenance/CreateEditPreventiveMaintenance";
import ChecklistActionList from "../Pages/MaintenanceBreakdown/MaintenanceChecklist/ChecklistActionList";
import LinkingToChecklistComponent from "../Pages/MaintenanceBreakdown/MaintenanceChecklist/MachineChecklistForm/LinkingToChecklistComponent";
import BreakdownLogs from "../Pages/MaintenanceBreakdown/Breakdown/BreakdownLogs";
import preventiveMiantainanceView from "../Pages/MaintenanceBreakdown/Maintenance/view";
import CreateEditEducationTraining from "../Pages/EducationTraining/CreateEditEducationTraining";
import EducationAndTraining from "../Pages/EducationTraining/EducationAndTrainingTiles";
import CreateEditTNI from "../Pages/EducationTraining/TniReport/CreateEditTNI";
import ViewTNI from "../Pages/EducationTraining/TniReport/View";
import TniList from "../Pages/EducationTraining/TniReport/TniList";
import QuestionPaperList from "../Pages/EducationTraining/QuestionPaper/QuestionPaperList";
import QuickViewQuestionPaper from "../Pages/EducationTraining/QuestionPaper/QuickViewQuestionPaper";
import FileList from "../Pages/EducationTraining/FileList";
import ScrapNoteList from "../Pages/ScrapNote/ScrapNoteList";
import ScrapAddEdit from "../Pages/ScrapNote/ScrapAddEdit";
import CreateEditQuestionPaper from "../Pages/EducationTraining/QuestionPaper/CreateEditQuestionPaper";
import SupplierLists from "../Pages/Supplier/SupplierLists";
import IRMResitersList from "../Pages/IRMResister/IRMResitersList";
import IRMViewFile from "../Pages/IRMResister/IRMViewFile";
import AddEditIRMResister from "../Pages/IRMResister/AddEditIRMResister";
import InsrumentsList from "../Pages/Instruments/InsrumentsList";
import CustomerList from "../Pages/CustomerComplaintTracker/MasterListofCustomer/CustomerList";
import Clause3 from "../components/Admin/clauses/Clause3";
import Clause1 from "../components/Admin/clauses/Clause1";
import Clause2 from "../components/Admin/clauses/Clause2";
import Clause4 from "../components/Admin/clauses/Clause4";
import Clause5 from "../components/Admin/clauses/Clause5";
import Clause6 from "../components/Admin/clauses/Clause6";
import Clause7 from "../components/Admin/clauses/Clause7";
import Clause8 from "../components/Admin/clauses/Clause8";
import Clause9 from "../components/Admin/clauses/Clause9";
import CreateEditCustomerComaplianTrackerList from "../Pages/CustomerComplaintTracker/CreateEditCustomerComaplianTrackerList";
import ComplaintFileList from "../Pages/CustomerComplaintTracker/ComplaintFileList";
import Clause10 from "../components/Admin/clauses/Clause10";
import Clause11 from "../components/Admin/clauses/Clause11";
import Clause12 from "../components/Admin/clauses/Clause12";
import Clause13 from "../components/Admin/clauses/Clause13";
import Clause14 from "../components/Admin/clauses/Clause14";
import Clause15 from "../components/Admin/clauses/Clause15";
import Clause16 from "../components/Admin/clauses/Clause16";
import Clause17 from "../components/Admin/clauses/Clause17";
import Clause18 from "../components/Admin/clauses/Clause18";
import Clause19 from "../components/Admin/clauses/Clause19";
import AnnualTrainingList from "../Pages/EducationTraining/AnnualTrainingPlan/AnnualTrainingList";
import CreateEditAnnalTraining from "../Pages/EducationTraining/AnnualTrainingPlan/CreateEditAnnalTraining";

// facultry import
import FacultyLists from "../Pages/EducationTraining/MasterListFaculty/FacultyLists";
import DepartmentsList from "../Pages/Departments/DepartmentsList";
//skill evaluation
import SkillEvaluationList from "../Pages/EducationTraining/SkillEvaluationCriteria/SkillEvalationList";
import AddEditViewSkillEvaluation from "../Pages/EducationTraining/SkillEvaluationCriteria/AddEditViewSkillEvaluation";
//skill evaluation plan list
import SkillEvaluationPlanList from "../Pages/EducationTraining/SkillEvaluationPlan/SkillEvaluationPlanList";
import CreateEditSkillEvaluationPlan from "../Pages/EducationTraining/SkillEvaluationPlan/CreateEditSkillEvaluationPlan";
// skill matrix import
import AddEditViewSkillMatrix from "../Pages/EducationTraining/SkillMarixSummary/AddEditViewSkillMatrix";
import SkillMatrixList from "../Pages/EducationTraining/SkillMarixSummary/SkillMatrixList";
import TilesInsideSkillEvaluation from "../Pages/EducationTraining/TilesInsideSkillEvaluation";

// supplier audit
import SupplierAuditEditView from "../Pages/SupplierAudit/SupplierAuditEditView";
import SupplierAuditPlanList from "../Pages/SupplierAudit/SupplierAuditPlanList";
import PlanStatusPage from "../Pages/SupplierAudit/PlanStatusPage";
import ListOfChecklist from "../Pages/SupplierAudit/Checklist/ListOfChecklist";
import EditViewChecklistSupplierAudit from "../Pages/SupplierAudit/Checklist/EditViewChecklistSupplierAudit";
import AllFileViewer from "../GlobalUtils/ShowFiles/AllFileViewer";
//internal audit module
import InternalAuditPlanList from "../Pages/InternalAuditModule/InternalAuditPlanList";
import InternalAuditEditView from "../Pages/InternalAuditModule/InternalAuditEditView";
import PlanStatusInternalAudit from "../Pages/InternalAuditModule/PlanStatusPage";
import CheckListInternalAudit from "../Pages/InternalAuditModule/Checklist/CheckList";
import SchedulePlanListInternalAudit from "../Pages/InternalAuditModule/SchedulePlan/SchedulePlanList";
// npd import
import NPDPartList from "../Pages/NPD/Part/PartList.jsx";
import AddEditViewSchedulePlan from "../Pages/InternalAuditModule/SchedulePlan/AddEditViewSchedulePlan";
import AddEditViewChecklistInternalIndex from "../Pages/InternalAuditModule/Checklist/DepartmentTable/AddEditView";
import NCSummary from "../Pages/InternalAuditModule/Checklist/NCSummary";
// process audit
import ProcessAuditPlanList from "../Pages/ProcessAuditModule/ProcessAuditPlanList";
import ProcessAuditEditView from "../Pages/ProcessAuditModule/ProcessAuditEditView";
import StatusPageProcessAudit from "../Pages/ProcessAuditModule/PlanStatusPage";
import ProcessAuditListOfChecklist from "../Pages/ProcessAuditModule/Checklist/ListOfChecklist";
import ProcessAuditEditViewChecklist from "../Pages/ProcessAuditModule/Checklist/EditViewChecklist";
//product audit
import ProductAuditPlanList from "../Pages/ProductAuditModule/ProductAuditPlanList";
import ApqpMatrixList from "../Pages/NPD/ApqpMatrix/ApqpMatrixList";
import CreateEditApqpMatrix from "../Pages/NPD/ApqpMatrix/CreateEditApqpMatrix";
import ApqpTimeplanList from "../Pages/NPD/ApqpTimePlan/ApqpTimeplanList";
import CreareEditApqpTimeplan from "../Pages/NPD/ApqpTimePlan/CreareEditApqpTimeplan";
import ViewFile from "../Pages/NPD/ApqpTimePlan/ViewFile";
import { SupplierRating, SupplierRatingTab } from "../Pages/SupplierRating";
import {
  EditRequestForm as abnormalityRequestForm,
  RequestForm,
  AbnormalityMonitoringList,
  AbnormalityMonitoringAddEditView,
} from "../Pages/AbnormalityManagement/AbnormalityManagement";
import Abnormality from "../components/Admin/clauses/abnormality";
//upload module
import BadAreaList from "../Pages/BadArea/BadAreaList";
import ViewBadAreaFile from "../Pages/BadArea/ViewBadAreaFile";
import ViewGoodAreaFile from "../Pages/GoodArea/ViewGoodAreaFile";
import GoodAreaList from "../Pages/GoodArea/GoodAreaList";
import ViewMasterProcedureFile from "../Pages/MasterProcedure/ViewMasterProcedureFile";
import MasterProcedureList from "../Pages/MasterProcedure/MasterProcedureList";
import InitialSupplyAddEdit from "../Pages/InitialSupplyMonitoring/InitialSupplyAddEdit";
import InitialSupplyList from "../Pages/InitialSupplyMonitoring/InitialSupplyList";
//4M
import MonitoringList from "../Pages/4M/4mMonitoring/MonitoringList";
import MonitoringAddEditView from "../Pages/4M/4mMonitoring/MonitoringAddEditView";
import RmTiles from "../components/Admin/clauses/RmTiles";
import { MushinHelpDesk } from "../Pages/Mushin_Help_Desk/Create.jsx";
import IncomingRawUpload from "../Pages/IncomingReport/IncomingRawUpload";
import DefectsList from "../Pages/masterListOfDefects/DefectList";
import LocationsList from "../Pages/listOfScrapLocations/ScrapLocationList";


import {
  CreateForMSetup,
  CreateForMSummary,
  CreateHoldCard,
  SummaryTableList,
  TrainingRecord,
} from "../Pages/4mNew";
import ScrapLocationsList from "../Pages/listOfScrapLocations/ScrapLocationList";

function Container() {
  const { state } = useAuth();
  window.onbeforeunload = function () {
    return false;
  };
  const ProtectedRoute = ({ children }) => {
    if (!state.user) {
      console.log("hey");
      // history.push("/");
    }
    return children;
  };

  if (state.user) {
    if (state.user.userType == 2) {
      return (
        <>
          <BrowserRouter>
            <Header />
            <Switch>
              <Route path="/mushinHelpDesk/" component={MushinHelpDesk} />

              <Route path="/account" component={Account} />
              <Route exact path={["/admin", "/"]} component={Admin} />
              <Route exact path="/dashboard" component={DashBoard} />
              <Route
                path="/rejectionAnalysis/partwiseAnalysis/:partName"
                component={PartWiseAnalysis}
              />
              <Route
                path="/rejectionAnalysis/failurewiseAnalysis/:partName"
                component={FailureModeWiseRejection}
              />
              <Route path="/userManagement" component={Users} />
              {/* new add part feature  prashant */}
              <Route path="/part_list" component={PartList} />
              <Route
                exact
                path="/quick_view_documents/:partId"
                component={QuickView}
              />
              <Route
                exact
                path="/edit/pfd/:partId/:id?"
                component={CreatePfd}
              />
              <Route
                exact
                path="/edit/fmea/:partId/:id?"
                component={CreatePfmea}
              />
              <Route
                exact
                path="/edit/controlplan/:partId/:id?"
                component={CreateControlPlan}
              />
              {/* end line of new add part feature */}
              <Route path="/pfd/diagram/:partId/:id" component={PfdDiagram} />
              <Route path="/view_process/:partId" component={AdminModules} />
              <Route path="/viewPartTable" component={ViewAdminPartTable} />
              <Route
                path="/revisedTable/:revisedType/:partId"
                component={RevisedTable}
              />
              <Route path="/fmea/diagram/:partId/:id" component={PfmeaIndex} />
              <Route path="/generatefmea/report/:id?" component={FmeaTable} />
              <Route path="/control_plan/:partId/:id" component={ControlPlan} />
              <Route path="/pcd/:partId" component={PCDIndex} />
              {/* PDIR */}
              <Route path="/pdir/edit/:id" component={UpdatePdir} />
              <Route path="/view/pdirTable/:id" component={ViewPdirExcel} />
              <Route
                exact
                path="/viewpdir/monthly/:partId"
                component={PdirMonthlyTable}
              />
              {/* End PDIR */}
              <Route exact path="/select/ppap/:partId" component={SelectPPAP} />
              <Route exact path="/ViewPir/:partId" component={AdminPirList} />
              <Route path="/generate/ppap/:partId" component={GeneratePPAP} />
              <Route path="/edit/ppap/:partId/:id" component={EditPPAP} />
              <Route
                path="/ViewSetupPiece/table/:id"
                component={ViewSetupPiece}
              />
              <Route path="/ViewPir/table/:id" component={TablePir} />
              <Route
                path="/ViewPir/updatePir/:partId/:id"
                component={AdminEditPir}
              />
              <Route
                exact
                path="/view4m/setupform/:id"
                component={ViewSetupForm}
              />
              <Route
                path="/view4m/requestform/:id"
                component={ViewRequestForm}
              />
              {/* spc */}
              <Route path="/spc/:partId" component={Test} />
              <Route exact path="/view/ppap" component={ViewPrintout} />
              <Route
                exact
                path="/viewfmeaTable/fmea/:partId"
                component={FmeaAllList}
              />
              <Route exact path="/view/fmea/:id" component={ViewFmeaTable} />
              <Route path="/view/msaTable/:instrumentId" component={MSATable} />
              <Route path="/create/msa/:instrumentId" component={CreateMSA} />
              <Route path="/edit/msa/:id" component={EditMSA} />
              <Route path="/view/msa/:id" component={null} />

              {/* Start 4m Monitoring Sheet */}
              <Route path="/admin/view/4mTable" component={AdminView4MTable} />
              <Route path="/viewMonitoringList" component={MonitoringList} />
              <Route
                path="/createMoniteringSheet"
                component={MonitoringAddEditView}
              />
              {/* End 4m Monitering Sheet */}

              {/* Abnormality Manangement */}
              <Route path="/abnormality" component={Abnormality} />
              <Route path="/abnormalityRequestForm" component={RequestForm} />
              <Route
                path="/abnormalityReportEditRequestForm/:id"
                component={abnormalityRequestForm}
              />
              {/* abnormality management */}
              {/* abnormality management */}
              <Route
                path="/abnormalityMonitoring"
                component={AbnormalityMonitoringList}
              />
              <Route
                path="/abnormalityMonitoringTable"
                component={AbnormalityMonitoringAddEditView}
              />
              {/* Incoming report routes for Admin*/}
              <Route
                path="/view/incomingTable/:partId"
                component={ViewIncomingTable}
              />
              <Route
                path="/create/incoming/:partId"
                component={CreateInspection}
              />
              <Route
                path="/view/incoming/excelTable/:id"
                component={ViewIncoming}
              />
              <Route path="/edit/incoming/:id" component={UpdateIncoming} />
              {/* customer drawing for Admin*/}
              <Route
                path="/customerDrawing/table/:partId"
                component={customerDrawingTable}
              />
              <Route
                path="/viewCustomerDrawing/:partId"
                component={ViewCustomerDrawing}
              />
              {/* daily production */}
              <Route
                path="/viewDailyProduction"
                component={ViewDailyProduction}
              />
              <Route
                path="/createDailyProduction/table/:id?"
                component={DialyProductionTable}
              />
              <Route
                path="/viewDailyProductiontable/:id?"
                component={ViewDailyProductionTable}
              />
              {/*   Production Planning & Control" */}
              <Route
                path="/productionPlanningcontrol/create"
                component={createProductionPlanning}
              />
              <Route
                path="/createProductionPlanning/table/:id?"
                component={createProductionPlanningTable}
              />
              <Route
                path="/viewProductionPlanning/:id?"
                component={viewProductionPlanningTable}
              />
              {/* Material TC for Admin*/}
              <Route path="/materialTc/table" component={MaterialTcTable} />
              <Route
                path="/viewMaterialTc/:partId"
                component={ViewCMaterialTc}
              />
              {/* Process Production TC for Admin*/}
              <Route
                path="/processProductData/table"
                component={ProcessProduct}
              />
              <Route
                path="/viewProcessProductData/:partId"
                component={ViewProcessProduct}
              />
              {/* Document & Data Control for Admin*/}
              <Route
                path="/documentDataControl/table"
                component={DocumentDataControl}
              />
              <Route
                path="/viewDocumentDataControl/:partId"
                component={ViewDocumentDataControl}
              />
              {/* Misc. Documents & Drawings for admin*/}
              <Route
                path="/miscDocumentsDrawings/table"
                component={MiscDocumentsDrawings}
              />
              <Route
                path="/viewMiscDocumentsDrawings/:partId"
                component={ViewMiscDocumentsDrawings}
              />
              {/* NoteBook  */}
              <Route path="/viewNotebooksList" component={Notebook} />
              <Route path="/view_noteslist/:id" component={Notes} />
              {/* Machine and maintenance */}
              <Route path="/machine_list" component={MachineList} />
              <Route
                exact
                path="/maintenance_breakdown"
                component={MaintenanceBreakdown}
              />
              <Route
                path="/maintenance_breakdown/maintenance"
                component={Maintenance}
              />
              <Route
                path="/createEditPreventive/maintenanceTable/:partId"
                component={CreateEditPreventiveMaintenance}
              />
              <Route
                path="/checklist_actions_list"
                component={ChecklistActionList}
              />
              <Route
                path="/maintenanceForCheckedMachineForm/:id/:checklistType"
                component={LinkingToChecklistComponent}
              />
              <Route
                path="/breakdown_logs/maintenance"
                component={BreakdownLogs}
              />
              {/* eduction and training */}
              <Route
                exact
                path="/educationAndTraining"
                component={EducationAndTraining}
              />
              <Route
                path="/educationAndTraining/create"
                component={CreateEditEducationTraining}
              />
              <Route path="/tniList/data" component={TniList} />
              <Route
                path="/createEditTNI/report/:id?"
                component={CreateEditTNI}
              />
              <Route path="/viewTNI/report/:id?" component={ViewTNI} />
              <Route path="/questionPaper/list" component={QuestionPaperList} />
              <Route
                path="/quick_view_questionpaper/:id/:levelType?"
                component={QuickViewQuestionPaper}
              />
              <Route path="/scrapNoteList" component={ScrapNoteList} />
              <Route path="/scrapNoteAddEdit/:id?" component={ScrapAddEdit} />
              <Route
                path="/createEditQuestionPaper/:id?"
                component={CreateEditQuestionPaper}
              />
              <Route
                path="/tilesOfSkillEvaluation"
                component={TilesInsideSkillEvaluation}
              />
              <Route
                path="/employeeFileList/:id/:objectKeyName"
                component={FileList}
              />
              {/* supplier  */}
              <Route path="/supplier/create/" component={SupplierLists} />
              {/* instrument */}
              <Route path="/instrumentsList/" component={InsrumentsList} />
              {/* // incoming raw material resister list */}
              <Route
                path="/incomingRMResisterList"
                component={IRMResitersList}
              />
              <Route
                path="/addEditIRMResister/:id?"
                component={AddEditIRMResister}
              />
              <Route path="/irmViewFile/:id" component={IRMViewFile} />
              {/* master list of customer */}
              <Route path="/customerList" component={CustomerList} />
              {/*  master list ofdepartment  */}
              <Route path="/allDepartment/" component={DepartmentsList} />
              {/* skill evaluation Criteria */}
              <Route
                path="/skillEvaluationList"
                component={SkillEvaluationList}
              />
              <Route
                path="/createEditViewSkillEvaluation/:id"
                component={AddEditViewSkillEvaluation}
              />
              {/* skill matrix  */}
              <Route path="/skillMatrixList" component={SkillMatrixList} />
              <Route
                path="/createEditViewSkillMatrix/:id"
                component={AddEditViewSkillMatrix}
              />
              {/*end skill matrix  */}
              {/* clauses pages  */}
              <Route path="/clause1Page" component={Clause1} />
              <Route path="/clause2Page" component={Clause2} />
              <Route path="/clause3Page" component={Clause3} />
              <Route path="/clause4Page" component={Clause4} />
              <Route path="/clause5Page" component={Clause5} />
              <Route path="/clause6Page" component={Clause6} />
              <Route path="/clause7Page" component={Clause7} />
              <Route path="/clause8Page" component={Clause8} />
              <Route path="/clause9Page" component={Clause9} />
              <Route path="/clause10Page" component={Clause10} />
              <Route path="/clause11Page" component={Clause11} />
              <Route path="/clause12Page" component={Clause12} />
              <Route path="/clause13Page" component={Clause13} />
              <Route path="/clause14Page" component={Clause14} />
              <Route path="/clause15Page" component={Clause15} />
              <Route path="/clause16Page" component={Clause16} />
              <Route path="/clause17Page" component={Clause17} />
              <Route path="/clause18Page" component={Clause18} />
              <Route path="/clause19Page" component={Clause19} />
              {/* end of clause */}
              <Route path="/rmTiles" component={RmTiles} />
              {/* NPD Route */}
              <Route path="/npd/part_list" component={NPDPartList} />
              <Route
                path="/create_edit_customer_compalint/list"
                component={CreateEditCustomerComaplianTrackerList}
              />
              <Route
                path="/complaintFileList/:id"
                component={ComplaintFileList}
              />
              {/* annual training */}
              <Route
                path="/create_edit_annualTraning/list/:id?"
                component={CreateEditAnnalTraining}
              />
              <Route
                path="/annualTrainingList"
                component={AnnualTrainingList}
              />
              {/* faculty */}
              <Route path="/facultyList" component={FacultyLists} />
              {/* supplier audit */}
              <Route
                path="/supplierAuditPlanList"
                component={SupplierAuditPlanList}
              />
              <Route
                path="/supplierAuditEditView/:id"
                component={SupplierAuditEditView}
              />
              <Route
                path="/viewStatusPageSupplierAudit/:id"
                component={PlanStatusPage}
              />
              <Route
                path="/listOfSupplierAuditChecklist/:auditId"
                component={ListOfChecklist}
              />

              <Route
                path="/editViewChecklistSupplierAudit/:auditId/:id?"
                component={EditViewChecklistSupplierAudit}
              />
              {/* internal audit */}
              <Route path="/internalAudit" component={InternalAuditPlanList} />
              <Route
                path="/internalAuditEditView/:id"
                component={InternalAuditEditView}
              />
              <Route
                path="/viewStatusPageInternalAudit/:id"
                component={PlanStatusInternalAudit}
              />
              <Route
                path="/listOfInternalAuditChecklist/:auditId"
                component={CheckListInternalAudit}
              />
              <Route
                path="/addEditViewInternalAuditChecklist/:auditId/:id?"
                component={AddEditViewChecklistInternalIndex}
              />
              <Route
                path="/listOfInternalAuditSchedulePlan/:auditId"
                component={SchedulePlanListInternalAudit}
              />
              <Route
                path="/addEditViewSchedulePlan/:auditId/:id?"
                component={AddEditViewSchedulePlan}
              />

              <Route path="/intenalAuditNcSummary" component={NCSummary} />

              {/* process audit */}
              <Route path="/ProcessAudit" component={ProcessAuditPlanList} />
              <Route
                path="/processAuditEditView/:id"
                component={ProcessAuditEditView}
              />
              <Route
                path="/viewStatusPageProcessAudit/:id"
                component={StatusPageProcessAudit}
              />
              <Route
                path="/listOfProcessAuditChecklist/:auditId"
                component={ProcessAuditListOfChecklist}
              />

              <Route
                path="/editViewChecklistProcessAudit/:auditId/:id?"
                component={ProcessAuditEditViewChecklist}
              />

              {/* product audit */}
              <Route path="/ProductAudit" component={ProductAuditPlanList} />

              {/* skill evaluation plan  */}
              <Route
                path="/skill_evaluation/list"
                component={SkillEvaluationPlanList}
              />
              <Route
                path="/createEditSkillEvaluationpPlan/:id"
                component={CreateEditSkillEvaluationPlan}
              />
              {/* All type file viewer */}
              <Route path="/showAllTypesFiles/:id?" component={AllFileViewer} />

              {/* npd routes */}
              <Route path="/viewFile/:partId/:id" component={ViewFile} />
              <Route path="/apqpMatrixList" component={ApqpMatrixList} />
              <Route
                path="/createEditApqpMatrix/:partId"
                component={CreateEditApqpMatrix}
              />

              <Route path="/apqpTimePlanList" component={ApqpTimeplanList} />
              <Route
                path="/createEditApqpTimePlan/:partId"
                component={CreareEditApqpTimeplan}
              />
              {/* Supplier Rating */}
              <Route path="/supplierRating" component={SupplierRating} />
              <Route path="/supplierRatingTab" component={SupplierRatingTab} />
              {/* Bad Area  */}
              <Route path="/badAreaList" component={BadAreaList} />
              <Route
                path="/viewBadAreaFile/:partId"
                component={ViewBadAreaFile}
              />
              {/* Good Area  */}
              <Route path="/goodAreaList" component={GoodAreaList} />
              <Route
                path="/viewGoodAreaFile/:partId"
                component={ViewGoodAreaFile}
              />
              <Route
                path="/viewMasterProcedureFile/:partId"
                component={ViewMasterProcedureFile}
              />
              {/*Master List of Procedure  */}
              <Route
                path="/masterProcedureList"
                component={MasterProcedureList}
              />
              <Route
                path="/viewMasterProcedureFile/:partId"
                component={ViewMasterProcedureFile}
              />
              {/* Initial Supply Monitoring */}
              {/* start new 4m */}
              <Route
                path="/common/create4mSummary"
                component={CreateForMSummary}
              />
              <Route
                path="/common/4mSummaryList"
                component={SummaryTableList}
              />
              <Route
                path="/common/create4mSetup/:partId/:rowId/:setupId?"
                component={CreateForMSetup}
              />
              <Route
                path="/common/createHoldCard/:id?"
                component={CreateHoldCard}
              />
              {/* end new 4m */}

              <Route path="/initialSupplyList" component={InitialSupplyList} />
              <Route
                path="/initialSupplyAddEdit/:partId/:id?"
                component={InitialSupplyAddEdit}
              />
              <Route
                path="/preventiveMiantainanceView/:partId"
                component={preventiveMiantainanceView}
              />
              <Route path="/defects" component={DefectsList} />
              <Route path="/scrapLocations" component={ScrapLocationsList} />

              <Route component={PageNotFound} />
            </Switch>
            {/* <Footer /> */}
          </BrowserRouter>
        </>
      );
    } else {
      return (
        <>
          <BrowserRouter>
            <Header />
            <Switch>
              <Route path="/account" component={Account} />
              <Route exact path={["/Supervisor", "/"]} component={Supervisor} />
              <Route path="/fmea/diagram/:partId/:id" component={PfmeaIndex} />
              <Route path="/viewPartTable" component={ViewAdminPartTable} />
              <Route exact path="/supervisor/dashboard" component={DashBoard} />
              <Route
                path="/rejectionAnalysis/partwiseAnalysis/:partName"
                component={PartWiseAnalysis}
              />
              <Route path="/control_plan/:partId/:id" component={ControlPlan} />
              <Route path="/pfd/diagram/:partId/:id" component={PfdDiagram} />
              <Route
                path="/rejectionAnalysis/failurewiseAnalysis/:partName"
                component={FailureModeWiseRejection}
              />
              <Route path="/pcd/:partId" component={PCDIndex} />
              <Route
                exact
                path="/supervisor/pir/:partId"
                component={SupervisorPirList}
              />
              <Route path="/supervisor/newpir/:partId" component={CreatePir} />
              <Route path="/ViewPir/table/:id" component={TablePir} />
              <Route
                path="/revisedTable/:revisedType/:partId"
                component={RevisedTable}
              />
              <Route
                exact
                path="/supervisor/viewparttable/:featureName"
                component={SupervisorPartTable}
              />
              {/* PDIR */}
              <Route
                exact
                path="/viewpdir/monthly/:partId"
                component={PdirMonthlyTable}
              />
              <Route exact path="/pdir/add/:partId" component={CreatePdir} />
              <Route path="/pdir/edit/:id" component={UpdatePdir} />
              <Route path="/view/pdirTable/:id" component={ViewPdirExcel} />
              {/* End PDIR */}
              <Route path="/view4m/setupform/:id" component={ViewSetupForm} />
              <Route
                path="/view4m/requestform/:partId/:id?"
                component={ViewRequestForm}
              />
              <Route
                path="/pir/save_later/:partId/:id"
                component={SupervisorEditPir}
              />
              <Route
                path="/ViewSetupPiece/table/:id"
                component={ViewSetupPiece}
              />
              <Route path="/view/msaTable/:instrumentId" component={MSATable} />
              <Route path="/create/msa/:instrumentId" component={CreateMSA} />
              <Route path="/edit/msa/:id" component={EditMSA} />
              <Route path="/view/msa/:id" component={null} />
              {/* Start 4m Monitoring Sheet */}
              <Route
                path="/supervisor/view/4mTable"
                component={SupervisorView4MTable}
              />
              <Route path="/viewMonitoringList" component={MonitoringList} />
              <Route
                path="/createMoniteringSheet"
                component={MonitoringAddEditView}
              />
              {/* End 4m Monitering Sheet */}
              {/* Abnormality Manangement */}
              <Route path="/abnormality" component={Abnormality} />
              <Route path="/abnormalityRequestForm" component={RequestForm} />
              <Route
                path="/abnormalityReportEditRequestForm/:id"
                component={abnormalityRequestForm}
              />
              <Route
                path="/abnormalityMonitoring"
                component={AbnormalityMonitoringList}
              />
              <Route
                path="/abnormalityMonitoringTable"
                component={AbnormalityMonitoringAddEditView}
              />
              {/* abnormality management */}
              {/* Incoming report routes */}
              <Route
                path="/incomingViewFile/:id"
                component={IncomingRawUpload}
              />
              <Route
                path="/view/incomingTable/:partId"
                component={ViewIncomingTable}
              />
              <Route
                path="/create/incoming/:partId"
                component={CreateInspection}
              />
              <Route
                path="/view/incoming/excelTable/:id"
                component={ViewIncoming}
              />
              <Route path="/edit/incoming/:id" component={UpdateIncoming} />
              {/* customer drawing for supervisor*/}
              <Route
                path="/customerDrawing/table/:partId"
                component={customerDrawingTable}
              />
              <Route
                path="/viewCustomerDrawing/:partId"
                component={ViewCustomerDrawing}
              />
              {/* Material TC for supervisor*/}
              <Route path="/materialTc/table" component={MaterialTcTable} />
              <Route
                path="/viewMaterialTc/:partId"
                component={ViewCMaterialTc}
              />
              {/* daily production */}
              <Route
                path="/viewDailyProduction/"
                component={ViewDailyProduction}
              />
              <Route
                path="/createDailyProduction/table/:id?"
                component={DialyProductionTable}
              />
              <Route
                path="/viewDailyProductiontable/:id?"
                component={ViewDailyProductionTable}
              />
              {/*   Production Planning & Control" */}
              <Route
                path="/productionPlanningcontrol/create"
                component={createProductionPlanning}
              />
              <Route
                path="/createProductionPlanning/table/:id?"
                component={createProductionPlanningTable}
              />
              <Route
                path="/viewProductionPlanning/:id?"
                component={viewProductionPlanningTable}
              />
              {/* Process Production TC for supervisor*/}
              <Route
                path="/processProductData/table"
                component={ProcessProduct}
              />
              <Route
                path="/viewProcessProductData/:partId"
                component={ViewProcessProduct}
              />
              {/* Document & Data Control for supervisor*/}
              <Route
                path="/documentDataControl/table"
                component={DocumentDataControl}
              />
              <Route
                path="/viewDocumentDataControl/:partId"
                component={ViewDocumentDataControl}
              />
              {/* Misc. Documents & Drawings for supervisor*/}
              <Route
                path="/miscDocumentsDrawings/table"
                component={MiscDocumentsDrawings}
              />
              <Route
                path="/viewMiscDocumentsDrawings/:partId"
                component={ViewMiscDocumentsDrawings}
              />
              {/* NoteBook  */}
              <Route path="/viewNotebooksList" component={Notebook} />
              <Route path="/view_noteslist/:id" component={Notes} />
              {/* Machine  and maintenace */}
              <Route path="/machine_list" component={MachineList} />
              <Route
                exact
                path="/maintenance_breakdown"
                component={MaintenanceBreakdown}
              />
              <Route
                path="/maintenance_breakdown/maintenance"
                component={Maintenance}
              />
              <Route
                path="/createEditPreventive/maintenanceTable/:partId"
                component={CreateEditPreventiveMaintenance}
              />
              <Route
                path="/checklist_actions_list"
                component={ChecklistActionList}
              />
              {/* parshant */}
              <Route
                path="/maintenanceForCheckedMachineForm/:id/:checklistType"
                component={LinkingToChecklistComponent}
              />
              <Route
                path="/breakdown_logs/maintenance"
                component={BreakdownLogs}
              />
              <Route path="/scrapNoteList" component={ScrapNoteList} />
              <Route path="/scrapNoteAddEdit/:id?" component={ScrapAddEdit} />
              // incoming raw material resister list
              <Route
                path="/incomingRMResisterList"
                component={IRMResitersList}
              />
              <Route path="/irmViewFile/:id" component={IRMViewFile} />
              <Route
                path="/addEditIRMResister/:id?"
                component={AddEditIRMResister}
              />
              {/* supplier  */}
              <Route path="/supplier/create/" component={SupplierLists} />
              {/* instrument */}
              <Route path="/instrumentsList/" component={InsrumentsList} />
              <Route path="/mushinHelpDesk/" component={MushinHelpDesk} />
              {/* employee */}
              {/* <Route
                exact
                path="/educationAndTraining"
                component={EducationAndTraining}
              />
              <Route
                path="/educationAndTraining/create"
                component={CreateEditEducationTraining}
              /> */}
              <Route component={PageNotFound} />
            </Switch>
            {/* <Footer /> */}
          </BrowserRouter>
        </>
      );
    }
  } else {
    return (
      <>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Login} />
          </Switch>
        </BrowserRouter>
      </>
    );
  }
}

export default Container;
